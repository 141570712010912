import React from "react";
import { Layout } from "../../components/Layout";
import { Head } from "../../components/Head";
import { CaseStudiesTitle } from "../../components/CaseStudiesTitle";
import { CaseStudiesText } from "../../components/CaseStudiesText";

export default function CS_Urology() {
  const title = "Urology";
  const subTitle = "泌尿器科";
  const overview = [
    "泌尿器科だけを単体で標榜科目にしているクリニックは比較的少なく、競合相手が少ないのも特徴です",
    <br />,
    "高齢化が進む郊外での開業には、大きな需要があります",
  ];
  const openingCash = "おおよその開業資金";
  const openingCashText = [
    "土地、建物　7,000万円～15,000万円",
    <br />,
    "設備　2,000〜2,500万円",
    <br />,
    "電子カルテ、レジスター、コピー複合機、診察用ベッド、内診台、X線撮影装置",
    <br />,
    "超音波断層撮影、膀胱ファイバースコープ",
  ];
  const point = "ポイント";
  const pointText = [
    "・男性と女性の動線を分ける",
    <br />,
    "・他の病院との連携",
    <br />,
    <br />,
    "男性より女性にとって他の診療科と比べて受診のハードルは高くなるため、",
    <br />,
    "動線を分けることで受信のハードルを下げる工夫が必要です",
    <br />,
    "今後も需要が高まる診療科目のため、他の病院と連携してより専門的な医療を提供できるような体制作りが重要です",
  ];

  return (
    <Layout>
      <Head props={subTitle} />
      <CaseStudiesTitle title={[title, subTitle]} />
      <CaseStudiesText
        textData={[overview, openingCash, openingCashText, point, pointText]}
      />
    </Layout>
  );
}
